import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature />
    <Features />
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Here are what some of our amazing customers are saying about our development professionals. "
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://ui-avatars.com/api/?name=Anssi+Piirainen",
          quote:
            "We hired Heldus to develop a web application for our startup. Heldus was a pleasure to work with. Developer was eager to learn the tech stack we were using and stepped up quickly to the challenges. I can recommend Heldus if you need a great developer.",
          customerName: "Anssi Piirainen",
          customerTitle: "CTO, Emmy Clothing Company, Finland"
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://ui-avatars.com/api/?name=Dan+Shevenell",
          quote:
            "Heldus is / has been a phenomenal teammate. I really appreciate that developer able to operate autonomously, taking ownership of a problem space. That said, Heldus is also great at checking-in, so — despite his autonomy — Heldus gives me the opportunity to provide design guidance, etc.",
          customerName: "Dan Shevenell",
          customerTitle: "Founder, Intripid.co, USA"
        }
      ]}
      textOnLeft={true}
    />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
