import React from "react";
import tw from "twin.macro";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo-full.png";
import AnchorLink from "react-anchor-link-smooth-scroll";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-40`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw(AnchorLink)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>
          <CopyrightText>
            HELDUS PRIVATE LIMITED<br/>
            CIN: U62091GJ2023PTC140575<br/>
            OM SOMNATH, K J VEKARIYA ROAD,<br/>
            RANCHHODNAGAR SOCIETY,<br/>
            RAJKOT-360003<br/>
            GUJARAT, INDIA
          </CopyrightText>
          <LinksContainer>
            <Link href="#Home">Home</Link>
            <Link href="#about">About</Link>
            <Link href="#services">Services</Link>
            <Link href="#customerStories">Testimonials</Link>
            <Link href="#FAQs">FAQs</Link>
          </LinksContainer>
          <CopyrightText>
            &copy; COPYRIGHT 2023 <br/> ALL RIGHTS RESERVED <br/> HELDUS PRIVATE LIMITED
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
